import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IoArrowBack, IoAdd, IoCloseOutline, IoPencil, IoChevronBackSharp } from 'react-icons/io5';
import moment from 'moment';
import { getAllProjects, selectAllProjects, removeTeamMember, addTeamMember, updateTeamMember } from '../../redux/features/projectsSlice';
import {   getAllWorkingEmployees,selectAllWorkingEmployees, } from '../../redux/features/employeesSlice';
import Select from 'react-select';
import { useStateContext } from "../../components/contexts/ContextProvider";
import { EditModal, DeleteModal } from "../../components/Modals";
import { toast } from 'react-toastify';

const ENGAGEMENT_TYPES = [
  { value: 'full_time', label: 'Full Time' },
  { value: 'part_time', label: 'Part Time' },
  { value: 'contract_based', label: 'Contract Based' },
  { value: 'freelance', label: 'Freelance' },
];

const ProjectView = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const userRole = state?.userRole || 'employee';
  const dispatch = useDispatch();
  const projects = useSelector(selectAllProjects);
  const loading = useSelector((state) => state.projects.loading);
  const project = projects?.find(p => p.id === (projectId));
  const [employees, setEmployees] = useState([]);
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [assignmentForm, setAssignmentForm] = useState({
    employee: '',
    start_date: null,
    end_date: null,
    engagement_type: ''
  });
  const [isTeamMemberModalOpen, setIsTeamMemberModalOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [teamMemberForm, setTeamMemberForm] = useState({
    employee: '',
    start_date: null,
    end_date: null,
    engagement_type: ''
  });
 
  const employeeNames = useSelector(selectAllWorkingEmployees);
 
  
  const [deleteId, setDeleteId] = useState(null);
  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDeleteModal,
    closeDeleteModal,
    portal,
  } = useStateContext();
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
  
        if (!projects?.length) {
          await dispatch(getAllProjects({}));
        }
        if (userRole === 'admin') {
        await dispatch(getAllWorkingEmployees({}));

      }
    };

    initializeData();
  }, [dispatch, projects]);



  const handleEditAssignment = (assignment) => {
    setSelectedAssignment(assignment);
    setAssignmentForm({
      employee: assignment.employee.id,
      start_date: new Date(assignment.start_date),
      end_date: new Date(assignment.end_date),
      engagement_type: assignment.engagement_type
    });
    setIsAssignmentModalOpen(true);
  };

  const handleAssignmentSubmit = async (e) => {
    e.preventDefault();

    dispatch(getAllProjects({}));
    setIsAssignmentModalOpen(false);
    setSelectedAssignment(null);
    setAssignmentForm({
      employee: '',
      start_date: null,
      end_date: null,
      engagement_type: ''
    });

  };

  const handleEditTeamMember = (member) => {
    if (!isAdmin()) return;
    setSelectedTeamMember(member);
    setTeamMemberForm({
      employee: member.employee_id,
      start_date: member.start_date ? moment(member.start_date).format('YYYY-MM-DD') : '',
      end_date: member.end_date ? moment(member.end_date).format('YYYY-MM-DD') : '',
      engagement_type: member.engagement_type
    });
    setEnable(true);
    openEditModal();
  };

  const handleTeamMemberSubmit = async (e) => {
    e.preventDefault();

    if (!teamMemberForm.employee) {
      setShowValidationMessage(true);
      return;
    }

    try {
      if (selectedTeamMember) {
        await dispatch(updateTeamMember({
          assignmentId: selectedTeamMember.id,
          formData: {
            ...teamMemberForm,
            project: projectId
          },
          toast: {
            success: (msg) => toast.success(msg),
            error: (msg) => toast.error(msg)
          }
        })).unwrap();
      } else {
        await dispatch(addTeamMember({
          formData: {
            ...teamMemberForm,
            project: projectId
          },
          toast: {
            success: (msg) => toast.success(msg),
            error: (msg) => toast.error(msg)
          }
        })).unwrap();
      }

      // Reset form and close modal
      setSelectedTeamMember(null);
      setTeamMemberForm({
        employee: '',
        start_date: null,
        end_date: null,
        engagement_type: ''
      });
      setShowValidationMessage(false);
      closeEditModal();
    } catch (error) {
      console.error('Error saving team member:', error);
    }
  };

  const handleRemoveTeamMember = (memberId) => {
    if (!isAdmin()) return;
    setDeleteId(memberId);
    openDeleteModal();
  };

  const handleDeleteConfirm = async () => {
    try {
      await dispatch(removeTeamMember({
        assignmentId: deleteId,
        toast: {
          success: (msg) => toast.success(msg),
          error: (msg) => toast.error(msg)
        }
      })).unwrap();

      setDeleteId(null);
      closeDeleteModal();
    } catch (error) {
      console.error('Error removing team member:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  if (!project) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-xl text-gray-600">Project not found</p>
        <button
          onClick={() => navigate('/projects')}
          className="mt-4 text-emerald-600 hover:text-emerald-700"
        >
          Back to Projects
        </button>
      </div>
    );
  }

  const getStatusBadgeColor = (status) => {
    const statusColors = {
      not_started: 'bg-gray-500',
      in_progress: 'bg-blue-500',
      completed: 'bg-green-500',
      on_hold: 'bg-yellow-500',
      canceled: 'bg-red-500',
    };
    return statusColors[status] || 'bg-gray-500';
  };

  const getStatusDisplayText = (status) => {
    const statusMap = {
      not_started: "Not Started",
      in_progress: "In Progress",
      completed: "Completed",
      on_hold: "On Hold",
      canceled: "Canceled",
    };
    return statusMap[status] || status;
  };

  const handleAddTeamMember = () => {
    if (!isAdmin()) return;
    setSelectedTeamMember(null);
    setTeamMemberForm({
      employee: '',
      start_date: null,
      end_date: null,
      engagement_type: ''
    });
    setEnable(true);
    openEditModal();
  };

  const isAdmin = () => userRole === 'admin';

  const renderTeamMembers = () => {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-800">Team Members</h2>
          {userRole === 'admin' && (
            <button
              onClick={handleAddTeamMember}
              className="flex items-center px-3 py-1 text-sm bg-emerald-500 text-white rounded hover:bg-emerald-600 transition-colors"
            >
              <IoAdd className="mr-1" />
              Add Member
            </button>
          )}
        </div>
        <div className="space-y-3">
          {project.team_members && project.team_members.length > 0 ? (
            project.team_members.map((member, index) => (
              <div key={index} className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
                <div className="flex items-center space-x-2">
                  <div>
                    <span className="text-black font-bold">{member.team_member_name}</span>
                    <p className="text-sm text-gray-500">
                      {member.engagement_type?.replace('_', ' ')?.charAt(0)?.toUpperCase() +
                        member.engagement_type?.slice(1)?.replace('_', ' ') || 'N/A'}
                    </p>
                    <p className="text-xs text-gray-400">
                      {member.start_date && `${new Date(member.start_date).toLocaleDateString()} - `}
                      {member.end_date ? new Date(member.end_date).toLocaleDateString() : 'Present'}
                    </p>
                  </div>
                </div>
                {userRole === 'admin' && (
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditTeamMember(member)}
                      className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                    >
                      <IoPencil className="w-5 h-5 text-inherit" />
                    </button>
                    <button
                      onClick={() => handleRemoveTeamMember(member.id)}
                      className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                    >
                      <IoCloseOutline className="w-5 h-5 text-inherit" />
                    </button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-500">No team members assigned</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <div className="h-[450px] grid place-content-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
        </div>
      ) : (
        <>
          <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-sm">
            <div className="flex py-3 px-4 items-center justify-between border-b-[1px] border-solid border-gray-200">
              <h5 className="mb-0 font-bold text-slate-600">
                <span className="text-emerald-500/90">
                  {project ? project.title : ""}
                </span>
              </h5>
            </div>

            <div className="md:m-4 m-1">
              {/* Basic Info Card */}
              <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-center justify-between">
                  <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                    Basic Info
                  </h5>
                </div>
                <div className="p-4">
                  <div className="grid md:grid-cols-2 gap-4">
                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/3 text-slate-600">Title:</h5>
                      <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                        {project.title}
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/3 text-slate-600">Status:</h5>
                      <h5 className="md:w-2/3 md:ml-0 ml-2">
                        <span className={`px-3 py-1 rounded-full text-white text-sm ${getStatusBadgeColor(project.status)}`}>
                          {getStatusDisplayText(project.status)}
                        </span>
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/3 text-slate-600">Added By:</h5>
                      <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                        {project.added_by}
                      </h5>
                    </div>

                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/3 text-slate-600">Tech Stack:</h5>
                      <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                        {project.tech_stack || "Not specified"}
                      </h5>
                    </div>
                  </div>

                  <div className="mt-4">
                    <h5 className="font-bold text-slate-600 mb-2 ml-4">Description:</h5>
                    <p className="text-slate-500 whitespace-pre-wrap ml-36">
                      {project.description || "No description provided"}
                    </p>
                  </div>
                </div>
              </div>

             
              <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25">
                  <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                    Timeline
                  </h5>
                </div>
                <div className="p-4">
                  <div className="grid md:grid-cols-2 gap-4">
                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/3 text-slate-600">Start Date:</h5>
                      <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                        {project.start_date ? moment(project.start_date).format('MMMM D, YYYY') : '--'}
                      </h5>
                    </div>
                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/3 text-slate-600">End Date:</h5>
                      <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                        {project.end_date ? moment(project.end_date).format('MMMM D, YYYY') : '--'}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

    
              <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25">
                  <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                    Leadership
                  </h5>
                </div>
                <div className="p-4">
                  <div className="grid md:grid-cols-2 gap-4">
                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/3 text-slate-600">Team Lead:</h5>
                      <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                        {project.team_lead?.team_lead_name || '--'}
                      </h5>
                    </div>
                    <div className="flex items-start md:px-4 md:gap-4">
                      <h5 className="font-bold md:w-1/3 text-slate-600">Account Manager:</h5>
                      <h5 className="md:w-2/3 md:ml-0 ml-2 text-slate-500">
                        {project.account_manager && project.account_manager.length > 0
                          ? project.account_manager[0].account_manager_name
                          : '--'}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* Team Members Card */}
              <div className="flex flex-col mt-4 min-w-0 bg-white bg-clip-border w-full border-solid border-[1px] rounded-md border-gray-200">
                <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex justify-between items-center">
                  <h5 className="sm:mb-2 md:mb-2 mb-0 font-bold py-2 px-1 text-slate-600">
                    Team Members
                  </h5>
                  {userRole === 'admin' && (
                    <button
                      onClick={handleAddTeamMember}
                      className="flex items-center px-4 py-2 text-base bg-emerald-500/70 text-white rounded-md hover:bg-emerald-500/90 transition-colors font-semibold"
                    >
                      <IoAdd className="mr-2 text-xl" />
                      Add Member
                    </button>
                  )}
                </div>
                <div className="p-4">
                  {project.team_members && project.team_members.length > 0 ? (
                    project.team_members.map((member, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between p-4 hover:bg-gray-50 rounded border-b border-gray-200 mb-3"
                      >
                        <div className="space-y-2">
                          <h3 className="text-lg font-bold text-gray-800">
                            {member.team_member_name}
                          </h3>
                          <div className="flex items-center gap-2">
                            <span className="px-3 py-1 bg-emerald-100 text-emerald-800 rounded-full text-sm font-medium">
                              {member.engagement_type?.split('_').map(word =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                              ).join(' ') || 'N/A'}
                            </span>
                          </div>
                          <div className="flex items-center gap-2 text-sm">
                            <span className="font-medium text-gray-600">Duration:</span>
                            <span className="text-gray-600">
                              {member.start_date ? new Date(member.start_date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              }) : '--'}
                              {' - '}
                              {member.end_date ? new Date(member.end_date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              }) : 'Present'}
                            </span>
                          </div>
                        </div>
                        {userRole === 'admin' && (
                          <div className="flex space-x-2">
                            <button
                              onClick={() => handleEditTeamMember(member)}
                              className="p-2 bg-emerald-500/70 text-slate-50 rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                            >
                              <IoPencil className="w-5 h-5" />
                            </button>
                            <button
                              onClick={() => handleRemoveTeamMember(member.id)}
                              className="p-2 bg-emerald-500/70 text-slate-50 rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
                            >
                              <IoCloseOutline className="w-5 h-5" />
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500">No team members assigned</p>
                  )}
                </div>
              </div>
            </div>

            {/* Back Button */}
            <div className="flex flex-wrap items-center justify-end py-3 px-4 border-t-[1px] border-solid border-gray-200">
              <button
                onClick={() => navigate(userRole === 'employee' ? '/projects/emp_projects' : '/projects')}
                className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md transition-all duration-300"
              >
                <IoChevronBackSharp />
              </button>
            </div>
          </div>
        </>
      )}

    
      {userRole === 'admin' && (
        <>
          <EditModal>
            <div className="bg-white rounded-lg w-[500px]">
              <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
                <h5 className="mb-0 font-bold text-slate-600">
                  {selectedTeamMember ? 'Edit Team Member' : 'Add Team Member'}
                </h5>
                <IoCloseOutline
                  className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
                  onClick={closeEditModal}
                />
              </div>
              <div className="m-1">
                <form onSubmit={handleTeamMemberSubmit} className="space-y-4 p-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Employee <span className="text-red-500 ml-1">*</span>
                    </label>
                    <Select
                      value={employeeNames?.find(emp => emp.id === teamMemberForm.employee)
                        ? {
                          value: teamMemberForm.employee,
                          label: employeeNames.find(emp => emp.id === teamMemberForm.employee).employee_name
                        }
                        : null
                      }
                      onChange={(selected) => {
                        setTeamMemberForm({
                          ...teamMemberForm,
                          employee: selected?.value
                        });
                        setShowValidationMessage(false);
                      }}
                      options={employeeNames
                        ?.filter(emp => {
                          const isAlreadyTeamMember = project.team_members?.some(
                            member => member.employee_id === emp.id
                          );
                          return !isAlreadyTeamMember || (selectedTeamMember && selectedTeamMember.employee_id === emp.id);
                        })
                        .map(emp => ({
                          value: emp.id,
                          label: emp.employee_name
                        })) || []
                      }
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      placeholder="Select Employee"
                      isLoading={!employeeNames}
                    />
                    {showValidationMessage && !teamMemberForm.employee && (
                      <p className="text-red-500 text-sm mt-1">Employee selection is mandatory</p>
                    )}
                    {!employeeNames && <p className="text-sm text-red-500">Loading employees...</p>}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Engagement Type</label>
                    <Select
                      value={ENGAGEMENT_TYPES.find(option => option.value === teamMemberForm.engagement_type)}
                      onChange={(selected) => setTeamMemberForm({
                        ...teamMemberForm,
                        engagement_type: selected.value
                      })}
                      options={ENGAGEMENT_TYPES}
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable

                      placeholder="Select Engagement Type"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                    <input
                      type="date"
                      value={teamMemberForm.start_date || ''}
                      onChange={(e) => setTeamMemberForm({
                        ...teamMemberForm,
                        start_date: e.target.value || null
                      })}
                     className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md disabled:bg-gray-100"

                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">End Date</label>
                    <input
                      type="date"
                      value={teamMemberForm.end_date || ''}
                      onChange={(e) => setTeamMemberForm({
                        ...teamMemberForm,
                        end_date: e.target.value || null
                      })}
                      className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md disabled:bg-gray-100"
                    />
                  </div>

                  <div className="flex gap-4 flex-wrap items-center py-3 px-4 border-t-[1px] border-solid border-gray-200">
                    <div className="flex-grow flex justify-end gap-4">
                      {enable && (
                        <button
                          type="submit"
                          className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                        >
                          Save
                        </button>
                      )}
                      <button
                        type="button"
                        className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                        onClick={closeEditModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </EditModal>
          <DeleteModal handleDelete={handleDeleteConfirm} />
        </>
      )}
    </>
  );
};

export default ProjectView;