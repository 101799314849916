import React, { useEffect, useState } from "react";
import { IoPencil, IoCloseOutline, IoEye } from "react-icons/io5";
import {
  deleteProject,
  getAllProjects,
  selectAllProjects,
  addProject,
  editProject,
  selectProjectCount,
  getTeamLeadProjects,
} from "../../redux/features/projectsSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EditModal, DeleteModal } from "../../components/Modals";
import Pagination from "../../components/Pagination";
import { useStateContext } from "../../components/contexts/ContextProvider";
import {
  getAllWorkingEmployees,
  selectAllWorkingEmployees,
} from "../../redux/features/employeesSlice";
import { selectUser } from "../../redux/features/authSlice";
import Select from "react-select";
import moment from "moment";

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projects = useSelector(selectAllProjects);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const count = useSelector(selectProjectCount);
  const projectLoading = useSelector((state) => state.projects.loading);
  const employeeNames = useSelector(selectAllWorkingEmployees);
  const newDate = new Date();
  const formattedDate = moment(newDate).format("YYYY-MM-DD");
  const [formValues, setFormValues] = useState({
    title: "",
    tech_stack: "",
    status: "",
    added_by: "",
    start_date: "",
    team_lead: "",
    team_members: [],
    end_date: "",
    description: "",
    account_manager: null,
  });
  const [errors, setErrors] = useState({});
  const [heading, setHeading] = useState("Add Project");
  const [deleteId, setDeleteId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const employeeLoading = useSelector((state) => state.employees.loading);
  const [employees, setEmployees] = useState([]);
  const {
    openEditModal,
    closeEditModal,
    enable,
    setEnable,
    openDeleteModal,
    closeDeleteModal,
    portal,
  } = useStateContext();
  const [searchData, setSearchData] = useState("");
  const [rowChange, setRowChange] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "asc",
  });
  const [noResults, setNoResults] = useState(false);
  const user = useSelector(selectUser);
  const userPortal = user?.portal || '';
  const isAdmin = userPortal === 'admin';



  console.log(user)
  useEffect(() => {
    setIsInitialLoad(false);

    dispatch(getAllWorkingEmployees({}));
    if (userPortal === 'admin') {
      dispatch(getAllProjects({}));
    } else if (userPortal === 'team_lead') {
      dispatch(getTeamLeadProjects({}));

    }
  }, [dispatch, userPortal]);

  const getStatusDisplayText = (status) => {
    const statusMap = {
      not_started: "Not Started",
      in_progress: "In Progress",
      completed: "Completed",
      on_hold: "On Hold",
      canceled: "Canceled",
    };
    return statusMap[status] || status;
  };


  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const dispatchProject = () => {
    const params = {
      searchData,
      status: formValues.status || "",
      toast,
      navigate,
      page_size: perPage * page,
      page: 1,
      rowChange,
      filterChange,
      portal,
    };

    if (isAdmin) {
      dispatch(getAllProjects(params));
    } else {
      dispatch(getTeamLeadProjects({
        ...params,
        teamLeadId: user?.id
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate title only
    if (!formValues.title.trim()) {
      setErrors({ ...errors, title: "Project title is required" });
      return;
    }

    try {
      const projectData = {
        ...formValues,
        added_by: user?.username || user?.email || "",
        team_lead: formValues.team_lead?.id || "",
        account_manager: formValues.account_manager?.id || null,
        start_date: formValues.start_date || null,
        end_date: formValues.end_date || null,
      };

      if (heading === "Update Project") {
        await dispatch(
          editProject({
            projectId: formValues.id,
            formValues: projectData,
            toast,
            closeEditModal,
            setEnable,
          })
        ).unwrap();
      } else {
        await dispatch(
          addProject({
            formValues: projectData,
            toast,
            setEnable,
          })
        ).unwrap();
      }

      setFormValues({
        title: "",
        tech_stack: "",
        status: "",
        added_by: "",
        start_date: "",
        team_lead: "",
        team_members: [],
        end_date: "",
        description: "",
        account_manager: null,
      });

      // Reset page and refresh list
      setPage(1);
      dispatch(
        getAllProjects({
          searchData,
          status: formValues.status || "",
          page_size: perPage,
          page: 1,
          portal,
          toast,
          navigate,
          filterChange: true
        })
      );
      closeEditModal();
    } catch (error) {
      toast.error("Failed to update project");
    }
  };

  const handleEdit = (row) => {
    const teamLeadData = row.team_lead
      ? {
          id: row.team_lead.team_lead_id,
          employee_name: row.team_lead.team_lead_name,
        }
      : null;

    const accountManagerData = row.account_manager
      ? {
          id: row.account_manager[0].account_manager_id,
          employee_name: row.account_manager[0].account_manager_name,
        }
      : null;

    setFormValues({
      id: row.id,
      title: row.title || "",
      description: row.description || "",
      tech_stack: row.tech_stack || "",
      status: row.status || "",
      start_date: row.start_date || "",
      end_date: row.end_date || "",
      team_lead: teamLeadData,
      account_manager: accountManagerData,
      added_by: row.added_by || "",
    });

    setErrors({});
    setHeading("Update Project");
    setEnable(true);
    openEditModal();
  };

  const handleView = (row) => {
    navigate(`/projects/${row.id}`, { state: { userRole: 'admin' } });
  };

  const pageChangeUseEffect = () => {
    if (
      projects.length < (page - 1) * perPage &&
      page === Math.ceil(count / perPage)
    ) {
      dispatch(
        getAllProjects({
          searchData,
          status: formValues.status || "",
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          portal,
          rowChange: true,
        })
      );
    } else if (
      (projects.length < page * perPage &&
        (!projects.length || projects.length < count) &&
        count !== 0) ||
      isInitialLoad
    ) {
      dispatch(
        getAllProjects({
          searchData,
          status: formValues.status || "",
          toast,
          navigate,
          page_size: perPage,
          page,
          rowChange,
          filterChange,
          isInitialLoad,
          portal,
        })
      );
      setIsInitialLoad(false);
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteProject({
        projectId: deleteId,
        toast,
        closeDeleteModal,
        setEnable,
      })
    )
      .unwrap()
      .then(() => {
        // Close the edit modal
        closeEditModal();

        // Refresh the projects list after successful deletion
        dispatch(
          getAllProjects({
            searchData,
            status: formValues.status || "",
            page_size: perPage,
            page,
            sortConfig,
            portal,
            toast,
            navigate,
          })
        );
      })
      .catch((error) => {
        toast.error("Failed to delete project");
      });
  };

  const handleNew = () => {
    setFormValues({
      title: "",
      description: "",
      status: "",
      team_lead: "",
      team_members: "",
      account_manager: null,
      start_date: "",
      end_date: "",
      added_by: "",
      tech_stack: "",
      team_member_name: "",
    });
    setErrors({});
    setHeading("Add Project");
    setEnable(true);
    openEditModal();
  };

  const filterData = () => {
    setSearchData(searchQuery);
    setFilterChange(true);
    setIsFilterApplied(true);
  };

  const ClearFilter = () => {
    dispatch(
      getAllProjects({
        page_size: perPage * page,
        page: 1,
        ClearFilter: true,
        portal,
      })
    );
    setSearchQuery("");
    setSearchData("");
    setFormValues((prev) => ({
      ...prev,
      status: "",
    }));
    setIsFilterApplied(false);
  };

  const clearFilter = () => {
    setSearchQuery("");
    setSearchData("");
    setFormValues((prevValues) => ({
      ...prevValues,
      status: "",
    }));
    setIsFilterApplied(false);
    setPage(1);
    setNoResults(false);
    setSortConfig({ key: "title", direction: "asc" });

    dispatch(
      getAllProjects({
        searchData: "",
        status: "",
        page_size: perPage,
        page: 1,
        sortConfig: { key: "title", direction: "asc" },
        portal,
        ClearFilter: true, // Add this flag
        toast,
        navigate,
      })
    );
  };

  const columns = [
    {
      name: (
        <div className="text-center w-full text-slate-600">Project Title</div>
      ),
      cell: (row) => (
        <div className="text-center w-full text-slate-500">{row.title}</div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Status</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {getStatusDisplayText(row.status)}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Team Lead</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.team_lead?.team_lead_name || "-"}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Account Manager</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.account_manager && row.account_manager.length > 0
            ? row.account_manager[0].account_manager_name
            : "-"}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Start Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.start_date ? moment(row.start_date).format("MMM D, YYYY") : "-"}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">End Date</div>,
      cell: (row) => (
        <div className="text-center w-full text-slate-500">
          {row.end_date ? moment(row.end_date).format("MMM D, YYYY") : "-"}
        </div>
      ),
    },
    {
      name: <div className="text-center w-full text-slate-600">Action</div>,
      cell: (row) => (
        <div className="w-full flex justify-center gap-2">
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => handleView(row)}
          >
            <IoEye className="w-5 h-5 text-inherit" />
          </button>
          <button
            className="cursor-pointer p-2 bg-emerald-500/70 text-slate-50 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300"
            onClick={() => handleEdit(row)}
          >
            <IoPencil className="w-5 h-5 text-inherit" />
          </button>
        </div>
      ),
    },
  ];

  const rowChangeUseEffect = () => {
    if (projects.length < count) {
      dispatch(
        getAllProjects({
          searchData,
          status: formValues.status || "",
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
          portal,
        })
      );
    }
    setRowChange(false);
  };

  const filterUseEffect = () => {
    if (filterChange) {
      dispatch(
        getAllProjects({
          searchData,
          status: formValues.status || "",
          toast,
          navigate,
          page_size: perPage * page,
          page: 1,
          rowChange,
          filterChange,
          portal,
        })
      );
      setFilterChange(false);
    }
  };

  // Inside the Projects component, add these style configurations
  const selectStyles = {
    control: (base) => ({
      ...base,
      minHeight: "42px",
      borderColor: "#d1d5db",
      "&:hover": {
        borderColor: "#d1d5db",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? "rgb(16 185 129 / 0.7)"
        : base.backgroundColor,
      "&:hover": {
        backgroundColor: state.isSelected ? "rgb(16 185 129 / 0.7)" : "#f3f4f6",
      },
    }),
  };

  return (
    <div>
      <EditModal>
        <div className="min-w-0 bg-white bg-clip-border w-full mx-1 my-2 rounded-md">
          <div className="flex py-3 px-4 items-center justify-between mb-0 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 rounded-t-md">
            <h5 className="mb-0 font-bold text-slate-600">{heading}</h5>
            <IoCloseOutline
              className="w-9 h-9 text-slate-500 hover:text-slate-400 transition-all duration-300 cursor-pointer"
              onClick={closeEditModal}
            />
          </div>
          <div className="m-1">
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 gap-2 mb-1 pb-4 px-1 py-3 border-[1px] border-solid border-slate-300/25 rounded-md">
                {/* Title Field */}
                <div className="col-span-2">
                  <label className="ml-1 mb-1 text-slate-600">
                    Project Title <span className="text-red-500">*</span>
                  </label>
                  <input
                    value={formValues.title}
                    onChange={handleChange}
                    className={`w-full p-2 text-slate-600 bg-clip-padding border-[1px] ${errors.title ? "border-red-500" : "border-gray-300"
                      } border-solid rounded-md disabled:bg-gray-100`}
                    type="text"
                    name="title"
                    disabled={!enable}
                  />
                  {errors.title && (
                    <p className="text-red-500 text-sm mt-1">{errors.title}</p>
                  )}
                </div>

                {/* Description Field */}
                <div className="col-span-2">
                  <label className="ml-1 mb-1 text-slate-600">
                    Description
                  </label>
                  <textarea
                    value={formValues.description}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md disabled:bg-gray-100"
                    name="description"
                    disabled={!enable}
                  />
                  {errors.description && (
                    <p className="form-errors">{errors.description}</p>
                  )}
                </div>

                {/* Remaining Fields */}
                <div>
                  <label className="ml-1 mb-1 text-slate-600">Tech Stack</label>
                  <input
                    value={formValues.tech_stack}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md disabled:bg-gray-100"
                    type="text"
                    name="tech_stack"
                    disabled={!enable}
                  />
                  {errors.tech_stack && (
                    <p className="form-errors">{errors.tech_stack}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Status</label>
                  <select
                    value={formValues.status}
                    onChange={handleChange}
                    className="w-full px-2 py-2.5 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md disabled:bg-gray-100"
                    name="status"
                    disabled={!enable}
                  >
                    <option value="">Select Status</option>
                    <option value="not_started">Not Started</option>
                    <option value="in_progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="on_hold">On Hold</option>
                    <option value="canceled">Canceled</option>
                  </select>
                  {errors.status && (
                    <p className="form-errors">{errors.status}</p>
                  )}
                </div>

                {/* Start Date */}
                <div>
                  <label className="ml-1 mb-1 text-slate-600">Start Date</label>
                  <input
                    value={formValues.start_date}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md disabled:bg-gray-100"
                    type="date"
                    name="start_date"
                    disabled={!enable}
                  />
                  {errors.start_date && (
                    <p className="form-errors">{errors.start_date}</p>
                  )}
                </div>

                {/* End Date */}
                <div>
                  <label className="ml-1 mb-1 text-slate-600">End Date</label>
                  <input
                    value={formValues.end_date}
                    onChange={handleChange}
                    className="w-full p-2 text-slate-600 bg-clip-padding border-[1px] border-gray-300 border-solid rounded-md disabled:bg-gray-100"
                    type="date"
                    name="end_date"
                    disabled={!enable}
                  />
                  {errors.end_date && (
                    <p className="form-errors">{errors.end_date}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Team Lead</label>
                  <Select
                    value={
                      formValues.team_lead
                        ? {
                          value: formValues.team_lead.id,
                          label: formValues.team_lead.employee_name,
                        }
                        : null
                    }
                    onChange={(selected) => {
                      setFormValues((prev) => ({
                        ...prev,
                        team_lead: selected
                          ? {
                            id: selected.value,
                            employee_name: selected.label,
                          }
                          : null,
                      }));
                    }}
                    options={employeeNames.map((emp) => ({
                      value: emp.id,
                      label: emp.employee_name,
                    }))}
                    isDisabled={!enable}
                    placeholder="Select Team Lead"
                    isClearable
                    styles={selectStyles}
                  />
                  {errors.team_lead && (
                    <p className="form-errors">{errors.team_lead}</p>
                  )}
                </div>

                <div>
                  <label className="ml-1 mb-1 text-slate-600">Account Manager</label>
                  <Select
                    value={
                      formValues.account_manager
                        ? {
                          value: formValues.account_manager.id,
                          label: formValues.account_manager.employee_name,
                        }
                        : null
                    }
                    onChange={(selected) => {
                      setFormValues((prev) => ({
                        ...prev,
                        account_manager: selected
                          ? {
                            id: selected.value,
                            employee_name: selected.label,
                          }
                          : null,
                      }));
                    }}
                    options={employeeNames.map((emp) => ({
                      value: emp.id,
                      label: emp.employee_name,
                    }))}
                    isDisabled={!enable}
                    placeholder="Select Account Manager"
                    isClearable
                    styles={selectStyles}
                  />
                  {errors.account_manager && (
                    <p className="form-errors">{errors.account_manager}</p>
                  )}
                </div>
              </div>



              {/* Footer Actions */}
              <div className="flex gap-4 flex-wrap items-center py-3 px-4 border-t-[1px] border-solid border-gray-200">
                <div className="flex-grow flex justify-between items-center">
                  {enable ? (
                    <>
                      <div>
                        <button
                          type="button"
                          className={`px-3 py-2 ${formValues.status === "canceled"
                              ? "bg-red-500/70 hover:bg-red-500/90 cursor-pointer"
                              : "bg-gray-400 cursor-not-allowed"
                            } text-slate-50 font-semibold rounded-md transition-all duration-300`}
                          onClick={() => {
                            if (formValues.status === "canceled") {
                              setDeleteId(formValues.id);
                              openDeleteModal();
                            } else {
                              toast.warning(
                                "Only canceled projects can be deleted"
                              );
                            }
                          }}
                          disabled={formValues.status !== "canceled"}
                        >
                          Delete
                        </button>
                      </div>
                      <div className="flex gap-4 justify-end">
                        <button
                          className="px-3 py-2 bg-emerald-500/70 hover:bg-emerald-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                          type="submit"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                          onClick={closeEditModal}
                        >
                          Close
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="w-full flex justify-end">
                      <button
                        type="button"
                        className="px-3 py-2 bg-gray-500/70 hover:bg-gray-500/90 text-slate-50 font-semibold rounded-md cursor-pointer transition-all duration-300"
                        onClick={closeEditModal}
                      >
                        Close
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </EditModal>

      <DeleteModal handleDelete={handleDelete} />

      <div className="flex flex-col justify-between lg:flex-row items-center mx-3">
        <div className="flex flex-col min-w-0 bg-white bg-clip-border w-full">
          <div className="mb-0 py-2 px-3 bg-gray-100 border-b-[1px] border-solid border-slate-300/25 flex flex-col md:flex-row items-start md:items-center justify-between flex-wrap">
            <h5 className="md:mb-0 max-md:mb-2 font-bold text-slate-600">
              Projects
            </h5>

            <div className="flex md:flex-row gap-2 md:items-center max-md:items-start flex-wrap">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Projects"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="rounded-lg p-2 w-40 h-10 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600 focus:ring-0"
                />
              </div>
              <div className="">
                <select
                  className="rounded-lg p-2 border-2 border-solid border-emerald-500/70 focus:border-emerald-500/90 text-slate-600"
                  value={formValues.status}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      status: e.target.value,
                    }))
                  }
                >
                  <option value="">All Status</option>
                  <option value="not_started">Not Started</option>
                  <option value="in_progress">In Progress</option>
                  <option value="completed">Completed</option>
                  <option value="on_hold">On Hold</option>
                  <option value="canceled">Canceled</option>
                </select>
              </div>
              <button
                type="button"
                className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                onClick={filterData}
              >
                Apply Filter
              </button>
              {isFilterApplied && (
                <button
                  type="button"
                  className="cursor-pointer px-3 py-2 bg-red-500 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={clearFilter}
                >
                  Clear Filter
                </button>
              )}
              {isAdmin && (
                <button
                  className="cursor-pointer px-3 py-2 bg-emerald-500/70 border-none rounded-lg hover:bg-emerald-500/90 transition-all duration-300 text-slate-50"
                  onClick={handleNew}
                >
                  Add New Project
                </button>
              )}
            </div>
          </div>
          <div className="card-body">
            {noResults ? (
              <div className="flex justify-center items-center p-8">
                <div className="text-center">
                  <p className="text-lg text-gray-500 mb-2">
                    No projects found
                  </p>
                  <p className="text-sm text-gray-400">
                    Try adjusting your search or filter criteria
                  </p>
                </div>
              </div>
            ) : (
              <Pagination
                data={projects}
                filterChange={filterChange}
                filterUseEffect={filterUseEffect}
                setRowChange={setRowChange}
                rowChangeUseEffect={rowChangeUseEffect}
                pageChangeUseEffect={pageChangeUseEffect}
                isLoading={projectLoading}
                count={count}
                columns={columns}
                page={page}
                perPage={perPage}
                setPerPage={setPerPage}
                setPage={setPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
